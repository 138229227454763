import router from '@/router'

/**
 * 后退
 */
export function goBack() {
  router.back()
}

export function empty(form) {
  if (!form) return
  form.resetFields()
}
