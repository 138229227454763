import Cookies from 'vue-cookie'

/**
 * 定义TOKEN的 key
 * @type {string}
 */
const TOKEN_KEY = 'token'

/**
 * 临时Token
 * @type {string}
 */
const TEMP_TOKEN_KEY = 'tempToken'

/**
 * 临时iD
 * @type {string}
 */
const TEMP_ID = 'tempId'

/**
 * 定义员工Id key
 * @type {string}
 */
const CLERK_ID_KEY = 'clerkId'
/**
 * 定义员工名称 key
 * @type {string}
 */
const CLERK_NAME_KEY = 'clerkName'
/**
 * 定义员工角色id key
 * @type {string}
 */
const ROLE_ID_KEY = 'roleId'

/**
 * 员工主题
 * @type {string}
 */
const CLERK_THEME = 'clerkTheme:'

/**************************************************** set ************************************************************/
/**
 * 设置token到cookies里面
 * @param params
 * @returns {*}
 */
export function setToken(params) {
  return window.localStorage.setItem(TOKEN_KEY, params)
}

/**
 * 设置WebSocket 临时token
 * @param params
 * @returns {*}
 */
export function setTempToken(params) {
  return sessionStorage.setItem(TEMP_TOKEN_KEY, params)
}

/**
 * 设置WebSocket 临时id
 * @param params
 * @returns {*}
 */
export function setTempId(params) {
  return sessionStorage.setItem(TEMP_ID, params)
}

/**
 * 设置员工id到cookies里面
 * @param params
 * @returns {*}
 */
export function setClerkId(params) {
  return window.localStorage.setItem(CLERK_ID_KEY, params)
}

/**
 * 设置员工名称到cookies里面
 * @param params
 * @returns {*}
 */
export function setClerkName(params) {
  return window.localStorage.setItem(CLERK_NAME_KEY, params)
}

/**
 * 设置员工名称到cookies里面
 * @param params
 * @returns {*}
 */
export function setClerkTheme(params) {
  return window.localStorage.setItem(CLERK_THEME, params)
}

/**************************************************** get ************************************************************/

/**
 * 获取token
 * @returns {*}
 */
export function getToken() {
  return window.localStorage.getItem(TOKEN_KEY)
}

/**
 * 获取WebSocket 临时token
 * @returns {*}
 */
export function getTempToken() {
  return sessionStorage.getItem(TEMP_TOKEN_KEY)
}

/**
 * 获取WebSocket 临时id
 * @returns {*}
 */
export function getTempId() {
  return sessionStorage.getItem(TEMP_ID)
}

/**
 * 获取员工id
 * @returns {*}
 */
export function getClerkId() {
  return window.localStorage.getItem(CLERK_ID_KEY)
}

/**
 * 获取员工名称
 * @returns {*}
 */
export function getClerkName() {
  return window.localStorage.getItem(CLERK_NAME_KEY)
}

/**
 * 获取员工角色id
 * @returns {*}
 */
export function getRoleId() {
  return window.localStorage.getItem(ROLE_ID_KEY)
}

/**
 * 获取员工主题
 * @returns {*}
 */
export function getClerkTheme() {
  return window.localStorage.getItem(CLERK_THEME)
}

/**************************************************** remove ************************************************************/
/**
 * 移除员工id
 * @returns {*}
 */
export function removeClerkId() {
  return window.localStorage.removeItem(CLERK_ID_KEY)
}

/**
 * 移除token
 * @returns {*}
 */
export function removeToken() {
  return window.localStorage.removeItem(TOKEN_KEY)
}

/**
 * 移除临时id
 * @returns {*}
 */
export function removeTempId() {
  return sessionStorage.removeItem(TEMP_ID)
}

/**
 * 移除临时token
 * @returns {*}
 */
export function removeTempToken() {
  return sessionStorage.removeItem(TEMP_TOKEN_KEY)
}

/**
 * 移除员工名称
 * @returns {*}
 */
export function removeClerkName() {
  return window.localStorage.removeItem(CLERK_NAME_KEY)
}

/**
 * 移除员工角色id
 * @returns {*}
 */
export function removeRoleId() {
  return window.localStorage.removeItem(ROLE_ID_KEY)
}

/**
 * 移除主题
 * @returns {*}
 */
export function removeClerkTheme() {
  return window.localStorage.removeItem(CLERK_THEME)
}

/**
 * 移除所有
 * @returns {*}
 */
export function remove() {
  window.localStorage.clear()
}

/**
 * 移除所有
 * @returns {*}
 */
export function removeTemp() {
  sessionStorage.removeItem(TEMP_ID)
  sessionStorage.removeItem(TEMP_TOKEN_KEY)
}
