import views from '@/router/modules/views'
const mutations = {
  //设置菜单数据
  setMenuList(state, list) {
    state.menuList = list
  },
  //设置菜单缩放
  setCollapse(state) {
    state.collapse = !state.collapse
  },
  //设置权限标识
  setCodeList(state, list) {
    state.codeList = list
    sessionStorage.setItem('codeList', JSON.stringify(list))
  },
  //添加页面选项卡集合
  setTagsList(state, val) {
    state.tagsList = val
  },
  setTags(state, val) {
    state.tagsList = val
    sessionStorage.setItem('tagsList', JSON.stringify(state.tagsList))
  },
  /**
   * 删除导航标签
   * @param state
   * @param val
   */
  delTags(state, val) {
    let tagsList =
      state.tagsList || JSON.parse(sessionStorage.getItem('tagsList'))
    if (tagsList && tagsList.length > 0) {
      //   //1.判断当前点中菜单是否在tabs里面
      let res = tagsList.findIndex((item) => item.path === val)
      if (res !== -1) {
        tagsList.splice(res, 1)
      }
    }
    state.tagsList = tagsList
    sessionStorage.setItem('tagsList', JSON.stringify(state.tagsList))
  },
  //添加页面选项卡对象
  setTag(state, val) {
    if (val) {
      //   //1.判断当前点中菜单是否在tabs里面
      let res = state.tagsList.findIndex((item) => item.path === val.path)
      //2.如果不存在，组织tabs数据，存放到editableTabs
      if (res === -1) {
        let tab = {}
        tab.title = val.title
        tab.path = val.path
        tab.query = val.query
        state.tagsList.push(tab)
      }
    }
    // // //3.把选中的选项卡存放到sessionStorage里面
    sessionStorage.setItem('tagsList', JSON.stringify(state.tagsList))
  },
  //获取标签
  getTagList(state) {
    const tagsList = JSON.parse(sessionStorage.getItem('tagsList'))
    if (tagsList) {
      state.tagsList = tagsList
    }
  },
  //设置路由数据
  setRouterList(state, list) {
    state.routerList = list
  },
  //生成动态路由
  dynamicRouter(state, router) {
    let routerList = state.routerList
    routerList.forEach((item) => {
      item.meta = { title: item.title }
      item.component = () => import(`@/views${item.url}`)
    })
    views.children = views.children.concat(routerList)
    router.addRoute(views)
  },
  setTheme(state, val) {
    state.isDarkMode = val.isDarkMode === 1
    state.theme = val
  },
  setMainHeight(state, val) {
    state.mainHeight = val
  },
  /**
   * 获取Websocket返回的数据
   * @param state
   * @param val
   */
  setWebsocketData(state, val) {
    state.websocketData = val
  },
  setWebsocketStatus(state, val) {
    state.websocketStatus = val
  },
  setUploadPercent(state, val) {
    state.uploadPercent = val
  }
}

export default mutations
