import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress'
import { getToken } from '@/utils/cookie.js'
import store from '@/store'
import routes from '@/router/modules/pages'
import { getMenuAccessAuthority } from '@/api/system/menu/menu'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  strict: true
})

/**
 * to router路由对象  跳转到的路由对象
 * from router 从哪一个路由对象跳转的对象
 * next版本4不推荐
 * 返回值
 *      1.false 不进行导航
 *      2 返回undefined 或者不写返回值  进行默认返回
 *      3.返回字符串 字符串需要是路径
 *      4.对象 router.push({path:"/test"})
 */
router.beforeEach(async (to, from) => {
  // 每次切换页面时，调用进度条
  NProgress.start()
  const token = getToken()
  if (token) {
    if (to.path === '/login') {
      return '/'
    }
    await store.dispatch('getMenuList')
    await store.commit('getTagList')
    if (store.state.theme.id === 0) {
      const theme = JSON.parse(localStorage.getItem('theme'))
      if (!theme) {
        await store.dispatch('getClerkThemeByClerkId')
      } else {
        store.commit('setTheme', theme)
      }
    }
    if (to.path !== '/404' && to.path !== '/401') {
      let res = await getMenuAccessAuthority({
        path: to.path
      })
      if (!res) {
        NProgress.done()
        return '/401'
      }
    }
  } else if (!token && to.path !== '/login') {
    NProgress.done()
    return '/login'
  }
  if (to.path !== '/home' && to.path !== '/login') {
    store.commit('setTag', {
      title: to.meta.title,
      path: to.path,
      query: to.query
    })
  }

  NProgress.done()
})
export default router
