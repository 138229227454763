import request from '@/api/index'
/**
 * 获获取员工临时Token
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getTempClerkToken() {
  return await request.post('/comm/getTempClerkToken', null)
}

/**
 * 根据pid获取省、市、区（县）
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getDistrictByIdOrPid(params) {
  return await request.getRESTfu('/district/getDistrictByIdOrPid.api/' + params)
}
