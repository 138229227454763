import { getMenuList } from '@/api/system/menu/menu'
import {
  getClerkThemeByClerkId,
  updateClerkThemeDarkModeByClerk
} from '@/api/platform/clerk/clerkTheme'
import { getClerkId } from '@/utils/cookie'

const actions = {
  //获取菜单和权限标识
  async getMenuList(context) {
    let res = await getMenuList()
    context.commit('setMenuList', res.menuList)
    context.commit('setRouterList', res.routerList)
    sessionStorage.setItem('codeList', JSON.stringify(res.codeList))
  },
  //暗黑模式开关
  async setDarkMode(context, val) {
    context.state.isDarkMode = val
    updateClerkThemeDarkModeByClerk({
      clerkThemeId: context.state.theme.id,
      isDarkMode: val
    }).then((res) => {
      localStorage.setItem('theme', JSON.stringify(res))
      context.commit('setTheme', res)
    })
  },
  async getClerkThemeByClerkId(context) {
    let res = await getClerkThemeByClerkId(getClerkId())
    if (res) {
      localStorage.setItem('theme', JSON.stringify(res))
      context.commit('setTheme', res)
    }
  }
}

export default actions
