const state = () => ({
  //菜单折叠
  collapse: false,
  //菜单数据
  menuList: [],
  //权限标识
  codeList: [],
  //路由数据
  routerList: [],
  //是否开启暗黑模式
  isDarkMode: false,
  //主题数据
  theme: {
    navBg: '#192027', //导航栏背景颜色
    navTextColor: '#a3a6ad', //导航栏文字颜色
    navActiveTextColor: '#FFFFFF', //导航栏选择文字颜色
    navBorderColor: '#2b2b2b', //导航栏边框颜色
    menuBg: '#28333e', //菜单背景颜色
    menuTextColor: '#FFFFFF', //菜单文字颜色
    menuActiveTextColor: '#FFFFFF', //菜单选择文字的颜色
    menuBorderColor: '#2b2b2b', //菜单边框颜色
    tagDivBg: '#3a3e4a', //标签页背景颜色
    tagDivBorderColor: '#2b2b2b', //标签页边框颜色
    tagBg: '#ADD8E6', //单标签页背景颜色
    tagTextColor: '#222226', //标签页文字颜色
    tagBorderColor: '#2b2b2b', //标签页边框颜色
    tagActiveTextColor: '#FFFFFF', //标签页选中文字颜色
    tagActiveBg: '#019688', //标签页选中背景
    tagActiveBorderColor: '#2b2b2b', //标签页选中背景
    mainBg: '#FFFFFF', //主窗口背景颜色
    id: 0
  },
  //面包屑数据
  tagsList: [],
  //主窗体高度
  mainHeight: 0,
  //websocket返回的数据
  websocketData: {},
  // websocket连接状态
  websocketStatus: 1,
  //文件的上传百分比，以小数形式呈现，例如，上传50%即为0.5
  uploadPercent: 0
})

export default state
