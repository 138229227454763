const routes = {
  path: '/index',
  name: 'index',
  redirect: '/',
  component: () => import('@/views/index/index.vue'),
  meta: {
    title: '首页'
  },
  children: [
    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/home/Home'),
      meta: { title: '首页' }
    },
    {
      path: '/401',
      name: '401',
      component: () => import('@/views/error/401.vue'),
      icon: '',
      meta: {
        title: '401'
      }
    },
    {
      path: '/404',
      name: '404',
      component: () => import('@/views/error/404.vue'),
      meta: {
        title: '404'
      }
    }
  ]
}

export default routes
