import { REQUEST_API_URL } from '@/api'
import {
  getClerkId,
  getToken,
  remove,
  removeTemp,
  setTempId,
  setTempToken
} from '@/utils/cookie'
import { getTempClerkToken } from '@/api/common/common'
import MessageUtils from '@/utils/message'
import router from '@/router/index'
import store from '@/store'

// const store = useStore()
let Socket = ''
let isConnect = false //连接断开修改标识
let setTime = null
var globalCallback = function () {}

/**
 * 建立连接
 * @param type 0临时连接 1非临时
 */
function createSocket(type) {
  return new Promise(async (resolve) => {
    Socket && Socket.close()
    if (Socket) {
      Socket = Socket.close()
    }
    if (!Socket) {
      if (type === 0) {
        let res = await getTempClerkToken()
        if (res) {
          setTempToken(res.tempToken)
          setTempId(res.tempId)
          Socket = new WebSocket(
            REQUEST_API_URL.replace('https', 'wss').replace('http', 'ws') +
              `/webSocket?token=${res.tempToken}&tempId=${res.tempId}&connType=0`
          )
        } else {
          await reConnect(type)
        }
      } else {
        let token = getToken()
        let clerkId = getClerkId()
        Socket = new WebSocket(
          REQUEST_API_URL.replace('https', 'wss').replace('http', 'ws') +
            `/webSocket?token=${token}&clerkId=${clerkId}&connType=1`
        )
      }
      if (Socket) {
        if (setTime) {
          clearInterval(setTime)
        }
        resolve(true)
        //已连接
        Socket.onopen = function () {
          onopenWS()
        }
        //连接异常
        Socket.onerror = function (e) {
          console.log(2)
          isConnect = false
          onerrorWS(e)
          reConnect() //连接错误 需要重连
        }
        Socket.onmessage = function (e) {
          console.log(3)
          onmessageWS(e)
        } //接收数据
        Socket.onclose = function () {
          console.log(4)
          oncloseWS(type)
        } //断开连接
      }
    } else {
      console.log(Socket)
    }
  })
}

/**打开WS之后发送心跳 */
function onopenWS() {
  console.log('连接成功！')
  store.commit('setWebsocketStatus', 1)
}

//
/**连接失败重连 */
function onerrorWS() {
  console.log('连接出现异常')
  Socket.close()
}

function getSock(callback) {
  globalCallback = callback
}

// 实际调用的方法
function sendSock(agentData, callback) {
  globalCallback = callback
  if (Socket.readyState === Socket.OPEN) {
    // 若是ws开启状态
    sendMessage(agentData)
  } else if (Socket.readyState === Socket.CONNECTING) {
    // 若是 正在开启状态，则等待1s后重新调用
    setTimeout(function () {
      sendSock(agentData, callback)
    }, 1000)
  } else {
    // 若未开启 ，则等待1s后重新调用
    setTimeout(function () {
      sendSock(agentData, callback)
    }, 1000)
  }
}

/**WS数据接收统一处理 */
function onmessageWS(e) {
  let data = JSON.parse(e.data)

  switch (data.head.code) {
    case '10000':
      globalCallback(data.body)
      break
    case '10001':
      MessageUtils.errorMsg(data.body.msg)
      return null
    case '10002':
      MessageUtils.warningMsg(data.body.msg)
      return null
    case '10003':
      remove()
      MessageUtils.warningMsg(data.body.msg)
      if (window.location.pathname !== '/login') {
        router.replace('/login')
      }
      return null
  }
}

//定义重连函数
async function reConnect(type) {
  console.log('尝试重新连接')
  if (!isConnect) {
    setTime = setTimeout(() => {
      createSocket(type)
    }, 5000)
  } //如果已经连上就不在重连了
}

/**
 * 发送数据
 * @param {any} message 需要发送的数据
 */
function sendMessage(message) {
  Socket.send(JSON.stringify(message))
}

/**
 * 连接断开
 */
async function oncloseWS(type) {
  isConnect = false
  store.commit('setWebsocketStatus', 0)
  removeTemp()
  Socket.close()
  await reConnect(type)
}

/**
 * 发送数据
 * @param {any} message 需要发送的数据
 */
function closeWs() {
  console.log('主动断开连接')
  removeTemp()
  Socket.close()
}

// createSocket()

export { createSocket, sendSock, getSock, closeWs }
