import { createStore } from 'vuex'
import mutations from './modules/mutations'
import actions from './modules/actions'
import state from './modules/state'
import getters from './modules/getters'

const store = createStore({
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
})

export default store
