import request from '@/api'

/**
 * 获取员工主题id
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getClerkThemeByClerkId(params) {
  return await request.getRESTfu(
    '/clerk-theme/getClerkThemeByClerkId.api/' + params
  )
}

/**
 * 根据员工id修改暗黑模式状态
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function updateClerkThemeDarkModeByClerk(params) {
  return await request.post(
    '/clerk-theme/updateClerkThemeDarkModeByClerk',
    params
  )
}
