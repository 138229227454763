import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'

import '@/static/css/style.css' //全局样式

/**
 * 引入elementPlus
 */
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn' //elementPlus 国际化配置
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css' //暗黑模式
import * as ElementPlusIconsVue from '@element-plus/icons-vue' //elementPlus icon
import * as websocket from '@/api/websocket/index'

import '@/static/icon/iconfont.css' //引入阿里图标
import 'font-awesome-animation/font-awesome-animation.scss'
import { getMenuRouterList } from '@/api/system/menu/menu' //font-awesome 图标库

//加载
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/cookie'
import hasMenu from '@/public/js/roleManager'

import * as globalMethods from '@/public/js/globalMethods'

const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

getMenuRouterList().then((res) => {
  if (res) {
    store.commit('setRouterList', res)
    store.commit('dynamicRouter', router)
    app.config.globalProperties.$websocket = websocket
    app.config.globalProperties.hasMenu = hasMenu
    app.config.globalProperties.globalMethods = globalMethods
    app.use(store)
    app.use(router)

    app.use(ElementPlus, {
      locale: zhCn
    })
  }
  app.mount('#app')
})
