import {
  ElMessage,
  ElMessageBox,
  ElLoading,
  ElNotification
} from 'element-plus'

var MessageUtils = {
  successMsg(text) {
    ElMessage({
      message: text,
      type: 'success',
      duration: 2000,
      showClose: true,
      customClass: 'messageIndex'
    })
  },
  errorMsg(text) {
    ElMessage({
      message: text,
      type: 'error',
      duration: 2000,
      showClose: true,
      customClass: 'messageIndex'
    })
  },
  warningMsg(text) {
    ElMessage({
      message: text,
      type: 'warning',
      duration: 2000,
      showClose: true,
      customClass: 'messageIndex'
    })
  },
  infoMsg(text) {
    ElMessage({
      message: text,
      type: 'info',
      duration: 2000,
      showClose: true,
      customClass: 'messageIndex'
    })
  },
  notification(text, type, position) {
    ElNotification({
      title: '提示',
      message: text,
      type: type,
      customClass: 'messageIndex',
      position: position ? position : 'top-right'
    })
  },
  confirmMsg(text) {
    return new Promise((resolve, reject) => {
      ElMessageBox.confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          resolve(true)
        })
        .catch(() => {
          reject(false)
        })
    }).catch(() => {})
  },
  loadingMsg(text) {
    return ElLoading.service({
      lock: true,
      text: text,
      spinner: 'fa fa-spinner animated faa-spin',
      background: 'hsla(0,0%,100%,.9)'
    })
  }
}

export default MessageUtils
