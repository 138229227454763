import request from '@/api'

//根据head角色id获取菜单
export async function getMenuList() {
  return await request.get('/menu/getMenuList.api', null)
}

//获取访问选项
export async function getMenuAccessAuthority(params) {
  return await request.post('/roleMenu/getMenuAccessAuthority.api', params)
}

//获取菜单路由
export async function getMenuRouterList() {
  return await request.get('/menu/getMenuRouterList.api', null)
}

//获取菜单翻页
export async function getMenuTrees(params) {
  return await request.get('/menu/getMenuTrees.api', params)
}

//根据角色id查询菜单
export async function getMenuByRoleId(params) {
  return await request.getRESTfu('/menu/getMenuByRoleId.api/' + params)
}
