import axios from 'axios'
import qs from 'qs' //序列化参数
import {
  getToken,
  getClerkId,
  getClerkName,
  getRoleId,
  remove
} from '@/utils/cookie'
import MessageUtils from '@/utils/message'
import { JsonGETConversion } from '@/utils/jsonUtil'
// export const REQUEST_API_URL = 'http://localhost:7070'
export const REQUEST_API_URL = 'https://api.chuangfu-manage.com'
export const THIS_API_URL = 'http://localhost:8080'

const SOURCECODE = 'PC_WEB'
const API = '/api/'
// const router = useRouter()

// 使用自定义配置新建一个 axios 实例
const instance = axios.create({
  // 基础的请求地址
  baseURL: REQUEST_API_URL + API,
  // 设置超时时间 5s
  timeout: 60000
})

// axios拦截器
instance.interceptors.request.use(
  (config) => {
    //可在此显示加载图标
    //可在此做拦截登录
    return config
  },
  (error) => {
    console.log('error:' + error)
  }
)

//响应拦截
instance.interceptors.response.use(
  (res) => {
    let data = res.data
    switch (data.head.code) {
      case '10000':
        return data.body
      case '10001':
        MessageUtils.errorMsg(data.body.msg)
        return null
      case '10002':
        MessageUtils.warningMsg(data.body.msg)
        return null
      case '10003':
        MessageUtils.warningMsg(data.body.msg)
        remove()
        return null
    }
    //请求成功后的拦截
    // return data.body;
  },
  (error) => {
    if (window.location.pathname !== '/login') {
      MessageUtils.warningMsg('系统出现异常，请联系管理员' + error)
    }
    //请求失败的拦截
  }
)

//格式化参数
export function formatParams(data, op) {
  let clerkId = getClerkId()
  let clerkName = getClerkName()
  let roleId = getRoleId()
  data = {
    body: { ...data },
    head: { clerkId: clerkId, clerkName: clerkName, op: op, roleId: roleId }
  }
  return data
}

//请求方法
const request = {
  post(url, params) {
    return instance.post(url, formatParams(params), {
      transformRequest: [
        (params) => {
          return JSON.stringify(params)
        }
      ],
      headers: {
        'Content-Type': 'application/lottie;charset=utf-8',
        sourceCode: SOURCECODE,
        token: getToken() || null
      }
    })
  },
  getRESTfu(url) {
    return instance.get(url, {
      transformRequest: [
        (params) => {
          return JSON.stringify(params)
        }
      ],
      headers: {
        sourceCode: SOURCECODE,
        'Content-Type': 'application/x-www-form-urlencoded',
        token: getToken() || localStorage.getItem('token'),
        clerkId: getClerkId()
      }
    })
  },
  get(url, params) {
    return instance.get(url, {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params)
      },
      headers: {
        sourceCode: SOURCECODE,
        token: getToken() || localStorage.getItem('token'),
        clerkId: getClerkId()
      }
    })
  }
}
export default request
